import { get, post } from "@/utils/https";

// 银行卡认证信息
export function getLatestBankCardInfo(bizNo) {
  if (!bizNo) return;
  return get(
    `/api/v1/common/saas/app/offline/apply/apply/${bizNo}/fourElements/latest`
  );
}

//  客户还款卡绑定
export function bindCard(data) {
  return post(`/api/v1/common/saas/app/offline/apply/apply/bind`, data);
}

//  发送协议绑卡短信
export function bindCardSms(data) {
  return post(
    `/api/v1/common/saas/app/offline/apply/bankCard/protocol/sms`,
    data
  );
}
