<template>
  <div class="fill-in-wrap">
    <!-- <div class="tips" v-if="tips">
      <span><van-icon name="info-o" /></span>
      {{ tips }}
    </div> -->
    <div class="content">
      <div class="input-wrap" v-for="(f, i) in fields" :key="i">
        <van-field
          :type="f.type"
          v-model.trim="form[f.key]"
          :label="f.title"
          :placeholder="`请输入`"
          :rules="required"
          required
          :disabled="f.disabled"
          input-align="right"
        >
          <template #label>
            {{ f.title }}
            <van-icon
              v-if="f.leftIcon"
              :name="f.leftIcon"
              :color="f.leftIconColor"
              @click="f.leftIconClick"
            />
          </template>
        </van-field>
      </div>
      <div class="input-wrap verify-code">
        <van-field
          label="验证码"
          v-model="form.verifyCode"
          placeholder="请输入验证码"
          required
          type="digit"
          :rules="required"
        />

        <div :class="['send-btn', sending ? 'sending' : '']" @click="getCode">
          {{ sending ? `${second}s` : "获取验证码" }}
        </div>
      </div>
      <div class="input-wrap">
        <van-checkbox v-model="checked" shape="square">
          <span class="font_28 van-field__label"
            >我已阅读并同意<span class="link" @click.stop="openContact"
              >《委托扣款协议》</span
            >
          </span>
        </van-checkbox>
      </div>
    </div>
    <div class="btns">
      <van-button type="info" size="small" @click="saveFn">提交</van-button>
    </div>
  </div>
</template>
<script>
import { bindCardSms, bindCard, getLatestBankCardInfo } from "@/api/bindCard";

import { mapGetters } from "vuex";
import { BIZINFO } from "@/utils/token";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  watch: {},
  data() {
    return {
      // 输入字段类型
      enterFields: ["text", "textarea", "number", "amount"],
      required: [
        {
          required: true
        }
      ],
      tips: "颠三倒四都是多所多所", // 警告提示信息
      form: {},
      sending: false, // loading
      second: 60,
      itv: null, // 定时器
      cacheData: {},
      checked: false // 是否签订协议
    };
  },
  computed: {
    /*
     * bankCardNo 银行卡号
      bizNo 订单号
      idCard 身份证号
      bankMobile 手机号
      name 姓名
      verifyCode  验证码
     */
    fields() {
      return [
        {
          title: "账户名",
          key: "customerName",
          type: "text",
          disabled: true
        },

        {
          title: "银行卡",
          key: "bankCardNo",
          type: "digit",
          reg: /^[1-9]\d{9,29}$/
        },
        {
          title: "开户银行",
          key: "bankName",
          type: "text",
          leftIcon: "info",
          leftIconColor: "#f5a227",
          leftIconClick: this.openBankList
        },
        {
          title: "银行预留手机号",
          key: "bankMobile",
          type: "digit",
          reg: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
        }
      ];
    }
  },
  created() {
    // 从本地缓存里面取数据
    const data = localStorage.getItem(BIZINFO) || "{}";
    this.cacheData = JSON.parse(data);

    if (this.cacheData) {
      const { customerName, certNo } = this.cacheData;
      this.$set(this.form, "customerName", customerName || "");
      this.$set(this.form, "customerIdCardNo", certNo || "");
    }
    // 从store里面取数据
    const bizNo = this.getBizNoQuery();
    this.$set(this.form, "bizNo", bizNo || "");
    if (!bizNo) {
      this.$router.push("/");
      this.$toast.fail("请从短信链接进入");
    }
  },
  mounted() {
    this.getLatestBankCardInfo();
  },
  methods: {
    ...mapGetters(["getBizNoQuery"]),
    // 校验
    checkedFields() {
      for (let i = 0; i < this.fields.length; i++) {
        const f = this.fields[i];
        const k = f.key;
        const name = f.title;
        const reg = f.reg;
        if (!this.form[k]) throw `${name}不能为空`;

        if (reg && !reg.test(this.form[k])) throw `请输入正确的${name}`;
      }
    },
    openBankList() {
      const url =
        "https://songche-prod.oss-cn-shanghai.aliyuncs.com/20240422/N4mmHHTTBSSMHUh00m6qO65eco4i21fm.pdf";
      this.$router.push(`/pdf?title=开户银行&url=${encodeURIComponent(url)}`);
    },
    openContact() {
      const url =
        "https://songcw-dev.oss-cn-shanghai.aliyuncs.com/agreement/%E4%BB%A3%E6%89%A3%E5%8D%8F%E8%AE%AE.pdf";

      this.$router.push(
        `/pdf?title=委托扣款协议&url=${encodeURIComponent(url)}`
      );
    },

    // 银行卡认证信息
    async getLatestBankCardInfo() {
      try {
        if (!this.form.bizNo) throw "订单编号错误";
        const res = await getLatestBankCardInfo(this.form.bizNo);
        const { accNo, idCard, mobile, name } = res.data || {};
        if (res.data) {
          this.form = {
            ...this.form,
            bankCardNo: accNo,
            customerIdCardNo: idCard,
            bankMobile: mobile,
            customerName: name
          };
        }
      } catch (error) {
        this.$errMsg(error);
      }
    },

    async getCode() {
      if (this.sending) return;

      try {
        if (!this.form.customerIdCardNo) {
          throw "缺少持卡人身份证号码";
        }
        // 校验用户输入区域
        await this.checkedFields();
        this.$toast.loading({
          message: "处理中...",
          forbidClick: true
        });
        const res = await bindCardSms(this.form);

        setTimeout(() => {
          this.$toast.success("验证码已发送，请注意查收！");
        }, 100);
        this.sending = true;
        this.itv = setInterval(() => {
          this.second--;
          if (this.second <= 0) {
            this.second = 60;
            this.sending = false;
            clearInterval(this.itv);
          }
        }, 1000);
        this.form.protocolPreNo = res.data; // 预绑卡编
        return Promise.resolve(true);
      } catch (error) {
        this.sending = false;

        this.$errMsg(error);
        return Promise.reject(error);
      }
    },
    async saveFn() {
      try {
        if (!this.checked) throw "请先阅读协议，并勾选";
        if (!this.form.verifyCode) {
          throw "请输入验证码";
        }
        if (!this.form.protocolPreNo) {
          throw "请发送短信验证码";
        }

        await this.checkedFields();
        this.$toast.loading({
          message: "处理中...",
          forbidClick: true
        });
        await bindCard(this.form);
        this.$toast.clear();
        setTimeout(() => {
          this.$toast.success("绑卡成功");
        }, 100);

        this.$router.push(`/index?bizNo=${this.form.bizNo}`);
      } catch (error) {
        this.$errMsg(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.fill-in-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  .tips {
    padding: 12px 24px;
    line-height: 40px;
    font-size: 24px;
    color: #f5a227;
    background: #fff1dc;
    // border: 1px solid #f5a227;
    min-height: 48px;
    box-shadow: 0 5px 5px #72634c15;
    margin-bottom: 5px;
  }
  .link {
    color: #1890ff;
    text-decoration: underline;
    cursor: pointer;
  }
  .content {
    flex: 1;
    overflow-y: auto;
    .input-wrap {
      width: 100%;
      height: 96px;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 0 32px;
      :deep(.van-field__label) {
        width: 7em;
      }
      &.verify-code {
        display: flex;
        justify-content: space-between;
        .send-btn {
          box-sizing: border-box;

          height: 28px;
          line-height: 28px;
          border-left: 2px solid #1890ff;
          font-size: 28px;
          font-weight: 400;
          color: #1890ff;
          text-align: center;
          padding-left: 6px;

          white-space: nowrap;

          padding: 0px 32px;
          &.sending {
            color: #999;
          }
        }
      }
    }
  }
  .btns {
    border-top: 1px solid #efefef;
    height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -5px 10px #efefef;
    .van-button {
      width: 90%;
      height: 75%;
    }
  }
}
</style>
